import React from 'react';
import { Box } from "rebass";

export const Container = props =>
  <Box
    mx='auto'
    style={{
      maxWidth: '1024px'
    }}
    {...props}
  />
