import axios from 'axios';

const IMAGE_WEIGHT = {
  '': 1, // missing size is ranked last
  small: 2,
  medium: 3,
  large: 4,
  extralarge: 5,
  mega: 6
};

// https://github.com/feross/last-fm/blob/master/index.js
class LastFM {
  private key = '';
  private BASE_URL = 'https://ws.audioscrobbler.com/2.0/';

  constructor(apiKey, opts) {
    this.key = apiKey;
  }

  _parseImages (image) {
    return image
      .sort((a, b) => IMAGE_WEIGHT[a.size] - IMAGE_WEIGHT[b.size])
      .filter(image => image.size !== '')
      .map(image => image['#text'])
      .filter(image => image.length > 0)
  }

  _parseMeta (data, query) {
    if (data['opensearch:totalResults']) {
      const total = Number(data['opensearch:totalResults'])
      const perPage = Number(data['opensearch:itemsPerPage'])
      const page = (Number(data['opensearch:startIndex']) / perPage) + 1
      const totalPages = Math.ceil(total / perPage)
      return { query, page, perPage, total, totalPages }
    } else {
      return {
        query,
        page: Number(data['@attr'].page),
        perPage: Number(data['@attr'].perPage),
        total: Number(data['@attr'].total),
        totalPages: Number(data['@attr'].totalPages)
      }
    }
  }

  _parseSummary (summary) {
    return summary.replace(/\s+?<a .*?>Read more on Last\.fm<\/a>.*$/, '')
  }

  /**
   * PARSE COMMON RESPONSE TYPES
   */

  _parseArtists (artists) {
    return artists
      .map(artist => {
        return {
          type: 'artist',
          name: artist.name,
          listeners: Number(artist.listeners),
          images: this._parseImages(artist.image)
        }
      })
      .filter(artist => artist.listeners == null)
  }

  _parseAlbums (albums) {
    return albums
      .map(album => {
        return {
          type: 'album',
          name: album.name,
          artistName: album.artist.name || album.artist,
          listeners: (
            (album.playcount && Number(album.playcount)) ||
            (album.listeners && Number(album.listeners))
          ), // optional
          images: this._parseImages(album.image)
        }
      })
  }

  _parseTags (tags) {
    return tags.tag.map(t => t.name)
  }

  _parseTracks (tracks) {
    return tracks
      .map(track => {
        const listeners = track.playcount || track.listeners
        return {
          type: 'track',
          name: track.name,
          artistName: track.artist.name || track.artist,
          duration: track.duration && Number(track.duration), // optional
          listeners: listeners && Number(listeners), // optional
          images: track.image && this._parseImages(track.image) // optional
        }
      })
      .filter(track => track.listeners == null)
  }

  _sendRequest (params, name) {
    const defaultParams = {
      api_key: this.key,
      format: 'json'
    };

    const opts = {
      url: this.BASE_URL,
      method: 'GET',
      timeout: 30 * 1000,
    };

    const finalParams = { ...defaultParams, ...params };

    const requestConfig = { ...opts, params: finalParams };


    // const opts = {
    //   url: urlBase + '?' + querystring.stringify(params),
    //   // headers: {
    //   //   'User-Agent': this._userAgent
    //   // },
    //   timeout: 30 * 1000,
    //   json: true
    // }

    return axios(requestConfig).then(res => res.data).then(data => {
      if (data.error) {
        throw new Error(data.message);
      }
      return data[name];
    });

  }
  trackInfo (opts) {
    if (!opts.name || !opts.artistName) {
      return Promise.reject(new Error('Missing required params: name, artistName'));
    }

    const params = {
      method: 'track.getInfo',
      track: opts.name,
      artist: opts.artistName,
      autocorrect: 1
    }

    return this._sendRequest(params, 'track')
      .then(track => ({
        type: 'track',
        name: track.name,
        artistName: track.artist.name,
        albumName: track.album ? track.album.title : '',
        listeners: Number(track.listeners),
        duration: Math.ceil(track.duration / 1000),
        images: track.album ? this._parseImages(track.album.image) : [],
        tags: this._parseTags(track.toptags),
      }))
      .catch(error => {
        console.error(error);
        return null;
      });
  }
}

// 85c47344e2459c67b500a26604b7b36e
export const lastfm = new LastFM('85c47344e2459c67b500a26604b7b36e', { });
