import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';

export const StyledInput = styled(Box)`
  font-family: inherit;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #e1e1e6;
  border-radius: 4px;
  appearance: none;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.blue};
  }
  &:disabled {
    opacity: 0.25
  }
`;

export const Input = props => 
  <StyledInput
    {...props}
    as='input'
  />
