import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Flex, Box, Button, Link } from 'rebass';
import { signin, signup } from '../actions/auth';
import { Lead } from '../components/Lead';
import { Input } from '../components/Input';

const LoginForm = props => (
  <form onSubmit={props.onSubmit}>
    <Lead mb={20}>Login to Lyrical</Lead>
    <Input placeholder='Email address' type='email' mb={20} value={props.email} onChange={props.onEmailChange} required={true} />
    <Input placeholder='Password' type='password' mb={20} value={props.password} onChange={props.onPasswordChange} required={true} />
    <Flex alignItems='center'>
      <Button type='submit'>Login</Button>
      <Link
        href='#!'
        onClick={props.onSwitchToSignup}
        children='or sign up'
        ml='auto'
      />
    </Flex>
  </form>
);

const SignupForm = props => (
  <form onSubmit={props.onSubmit}>
    <Lead mb={20}>Sign up to Lyrical</Lead>
    <Input placeholder='Your Name' mb={20} value={props.displayName} onChange={props.onNameChange} required={true} />
    <Input placeholder='Email address' type='email' mb={20} value={props.email} onChange={props.onEmailChange} required={true} />
    <Input placeholder='Password' type='password' mb={20} value={props.password} onChange={props.onPasswordChange} required={true} />
    <Flex alignItems='center'>
      <Button type='submit'>Create Account</Button>
      <Link
        href='#!'
        onClick={props.onSwitchToSignin}
        children='or sign in'
        ml='auto'
      />
    </Flex>
  </form>
);

class LoginScreen extends Component<{ signin, signup }> {
  state = {
    email: '',
    password: '',
    displayName: '',
    isSignup: false
  };

  onLogin = (evt) => {
    evt.preventDefault();
    this.props.signin({ email: this.state.email, password: this.state.password });
  };
  onSignup = (evt) => {
    evt.preventDefault();
    this.props.signup({ email: this.state.email, password: this.state.password, displayName: this.state.displayName });
  };
  onNameChange = evt => this.setState({ displayName: evt.target.value });
  onEmailChange = evt => this.setState({ email: evt.target.value });
  onPasswordChange = evt => this.setState({ password: evt.target.value });
  onSwitchToSignup = evt => {
    evt.preventDefault();
    this.setState({ isSignup: true });
  }
  onSwitchToSignin = evt => {
    evt.preventDefault();
    this.setState({ isSignup: false });
  };

  render() {
    return (
      <div className='login-screen'>
        <Flex justifyContent='center'>
          <Box>
            { this.state.isSignup ?
                <SignupForm
                  displayName={this.state.displayName}
                  onNameChange={this.onNameChange}

                  email={this.state.email}
                  onEmailChange={this.onEmailChange}

                  password={this.state.password}
                  onPasswordChange={this.onPasswordChange}

                  onSwitchToSignin={this.onSwitchToSignin}

                  onSubmit={this.onSignup}
                />
              :
                <LoginForm
                  email={this.state.email}
                  onEmailChange={this.onEmailChange}

                  password={this.state.password}
                  onPasswordChange={this.onPasswordChange}

                  onSwitchToSignup={this.onSwitchToSignup}

                  onSubmit={this.onLogin}
                />
              }
          </Box>
        </Flex>
      </div>
    );
  }
}

export default connect(state => state, { signin, signup })(LoginScreen);
