import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Card, Image } from 'rebass';
import { Lead } from './Lead';
import { Small } from './Small';
import { ReactComponent as AudioIcon } from '../icons/volume-full.svg';

class LyricItemCard extends Component<any> {
  onGoToLyric = () => this.props.push(`/lyric/${this.props.lyric.id}`);
  render() {
    const { lyric } = this.props;

    let albumArt = 'https://firebasestorage.googleapis.com/v0/b/lyrical-34f56.appspot.com/o/default-cover-art.png?alt=media&token=d8ac0de2-cb07-4da9-927a-5a7e75c1517a';
    const lastFmAlbumeArt = lyric.lastfmMeta && lyric.lastfmMeta.images && lyric.lastfmMeta.images[lyric.lastfmMeta.images.length - 1];
    albumArt = lastFmAlbumeArt || albumArt;

    return(
      <Card className='lyric-item-card' p={30} boxShadow={5} mb={this.props.mb} onClick={this.onGoToLyric}>
        {
          albumArt ?
          <div className='lyric-item__media'>
            <Image
              src={albumArt}
              width='100%'
            />
          </div>
          :
          null
        }
        <div className="lyric-item__content">
          <Lead>
            <span className='align-middle'>{lyric.data.title}</span>
          </Lead>
          <Small>
            <span className="align-middle">Key: {lyric.data.key}</span>
            <span className='align-middle'>{' '}&middot; {lyric.data.year}</span>
            <span className='align-middle'>{' '}&middot; {lyric.data.artists.join(', ')}</span>
            { lyric.data.youtubeUrl ?
              <React.Fragment>{' '}&middot; <AudioIcon className='align-middle' width='12px' height='12px' /></React.Fragment>
              :
              null
            }
          </Small>
        </div>
      </Card>
    );
  }
}
export default connect(null, { push })(LyricItemCard);
