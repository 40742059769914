import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  setDOMBlock,
  getLinesFromDOM,
  getKeyFromNash,
  NASH_KEYS,
  CHORD_TYPES,
  MUSICAL_KEYS,
  isSelectionInSelector,
  getDOMStringForLyricSection,
  lyricSectionToSlateValue,
  slateValueToLyricLines,
  LYRIC_LINE_BLOCK_TYPE
} from '../utils';
import { connect } from 'react-redux';
import TagsInput from 'react-tagsinput';
import LyricSectionEditor from '../components/LyricSectionEditor';
import { Button, Box } from 'rebass';
import { createNewLyric } from '../actions/lyrics';
import { toast } from 'react-toastify';
import { fetchLyricById, updateLyricById } from '../actions/lyric';
import Loader from '../components/Loader';
import { Input } from '../components/Input';
import { Row } from '../components/Row';
import { Column } from '../components/Column';
import { Group } from '../components/Group';
import { Select } from '../components/Select';
import { LyricalData, LyricSection } from '../interfaces';
import { Value } from 'slate';

const initialSectionsData = () => ({
  title: '',
  slateValue: Value.fromJSON({
    document: {
      nodes: [
        {
          object: 'block',
          type: LYRIC_LINE_BLOCK_TYPE,
          nodes: [],
        },
      ]
    }
  }),
});

interface EditorParams {
  lyricId: string;
}
interface OwnProps {
}
interface StateProps {
  lyric;
}
interface DispatchProps {
  fetchLyricById;
  createNewLyric;
  updateLyricById;
}

type EditorProps = RouteComponentProps<EditorParams> & StateProps & DispatchProps & OwnProps;
interface EditorState {
  sectionsData: any[],
  originalKey: string,
  selectedKey: string,
  selectedChordType: string,
  selectedSlashKey: string,
  keys: string[],
  chords: string[],
  songTitle: string,
  albumTitle: string,
  bpm: string,
  artists: any[],
  year: string,
  tags: any[],
  youtubeUrl: string,
  isNew: boolean,
  lyricId: string,
}
class Editor extends Component<EditorProps, EditorState> {
  editorEl = null;

  constructor(props) {
    super(props);
    const { match: { params } } = props;

    /**
     * @type Lyric
     */
    // let lyricalData = null;

    // if (params.lyricId) {
    //   lyricalData = JSON.parse(localStorage.getItem('lyricaldata')); 
    // }

    this.state = {
      sectionsData: [ initialSectionsData() ],
      originalKey: 'C',
      selectedKey: 'C',
      selectedChordType: 'major',
      selectedSlashKey: '',
      keys: MUSICAL_KEYS,
      chords: CHORD_TYPES,
      songTitle: '',
      albumTitle: '',
      bpm: '',
      artists: [],
      year: '',
      tags: [],
      youtubeUrl: '',
      isNew: !params.lyricId,
      lyricId: params.lyricId,
    };

    // if (lyricalData) {
    //   this.state.sectionsDOMs = lyricalData.sections.map(section => ({
    //     title: section.title,
    //     html: getDOMStringForLyricSection(section)
    //   }));
    //   this.state.originalKey = lyricalData.key;
    //   this.state.songTitle = lyricalData.title || '';
    // }
  }
  componentDidMount() {
    const { match: { params }, lyric } = this.props;

    if (params.lyricId) {
      this.props.fetchLyricById(params.lyricId);
    }

    if (!this.state.isNew && lyric.data) {
      this.loadLyric(lyric.data.data);
    }
  }
  componentWillReceiveProps(newProps) {
    // Set the internal state if the lyric state changes
    if (newProps.lyric.data && JSON.stringify(newProps.lyric.data) !== JSON.stringify(this.props.lyric.data || {})) {
      const lyricalData = newProps.lyric.data.data;

      this.loadLyric(lyricalData);
    }
  }

  loadLyric = (lyricalData: LyricalData) => {
    this.setState({
      originalKey: lyricalData.key,
      songTitle: lyricalData.title,
      albumTitle: lyricalData.album,
      bpm: lyricalData.bpm,
      artists: lyricalData.artists,
      year: lyricalData.year,
      tags: lyricalData.tags,
      youtubeUrl: lyricalData.youtubeUrl || '',
      sectionsData: lyricalData.sections.map(section => ({
        title: section.title,
        slateValue: lyricSectionToSlateValue(section),
      })),
    });
  };

  handleChange = (data, i) => {
    this.setState({
      sectionsData: this.state.sectionsData.map((sectionData, idx) => {
        if (idx === i) {
          sectionData.title = data.title;
          sectionData.slateValue = data.slateValue;
        }
        return sectionData;
      })
    });
  };

  selectKey = evt => this.setState({ selectedKey: evt.target.value, selectedSlashKey: '' });
  selectChord = evt => this.setState({ selectedChordType: evt.target.value });
  selectSlashKey = evt => this.setState({ selectedSlashKey: evt.target.value });
  selectOriginalKey = evt => this.setState({ originalKey: evt.target.value });
  setSongTitle = evt => this.setState({ songTitle: evt.target.value });
  setAlbumTitle = evt => this.setState({ albumTitle: evt.target.value });
  setBPM = evt => this.setState({ bpm: evt.target.value });
  setReleaseYear = evt => this.setState({ year: evt.target.value });
  onArtistsChange = tags => this.setState({ artists: tags });
  onTagsChange = tags => this.setState({ tags });
  setYoutubeUrl = evt => this.setState({ youtubeUrl: evt.target.value });

  addSection = evt => this.setState({ sectionsData: [ ...this.state.sectionsData, initialSectionsData() ] });

  setBlock = () => {
    if (!isSelectionInSelector('.editor-viewer')) {
      return;
    }

    setDOMBlock({ key: this.state.selectedKey, chord: this.state.selectedChordType, slashKey: this.state.selectedSlashKey });
  };

  saveLyrics = (e) => {
    const sections = this.state.sectionsData.map(sectionData => {
      return {
        title: sectionData.title,
        lines: slateValueToLyricLines(sectionData.slateValue),
      };
    });

    const lyricalData = {
      key: this.state.originalKey,
      sections,
      title: this.state.songTitle,
      album: this.state.albumTitle,
      bpm: this.state.bpm,
      tags: this.state.tags,
      artists: this.state.artists,
      year: this.state.year || new Date().getFullYear(),
      youtubeUrl: this.state.youtubeUrl,
    };

    // Validate input
    if (!(
      lyricalData.key &&
      lyricalData.sections.length &&
      lyricalData.title &&
      lyricalData.artists.length
    )) {
      // Show error
      toast.error('Fill all required fields before saving');
      e.preventDefault();
      return false;
    }

    // localStorage.setItem('lyricaldata', JSON.stringify(lyricalData));
    if (this.state.isNew) {
      this.props.createNewLyric({ data: lyricalData });
    } else {
      this.props.updateLyricById({ lastfmMeta: this.props.lyric.lastfmMeta, data: lyricalData, id: this.state.lyricId });
    }

    console.log(lyricalData);
    e.preventDefault();
  };

  render() {

    const {
      originalKey,
      songTitle,
      albumTitle,
      artists,
      bpm,
      year,
      tags,
      youtubeUrl
    } = this.state;
    return (
      <div className='editor-screen'>
        <Loader loading={this.props.lyric.loading} />
        <form onSubmit={this.saveLyrics}>
          <div className='editor-meta-block'>
            <Input
              mb={10}
              onChange={this.setSongTitle}
              placeholder="Song Title"
              value={songTitle}
              required={true}
            />
            <Input
              mb={10}
              onChange={this.setAlbumTitle}
              placeholder="Album Title"
              value={albumTitle}
            />
            <TagsInput value={artists} onChange={this.onArtistsChange} inputProps={{ placeholder: 'Artists' }} />
            <TagsInput value={tags} onChange={this.onTagsChange} inputProps={{ placeholder: 'Tags' }} />
            <Row>
              <Column>
                <Input
                  mb={10}
                  onChange={this.setBPM}
                  placeholder='BPM'
                  value={bpm}
                />
              </Column>
              <Column>
                <Input
                  mb={10}
                  onChange={this.setReleaseYear}
                  placeholder='Release Year'
                  value={year}
                />
              </Column>
              <Column>
                <Input
                  mb={10}
                  onChange={this.setYoutubeUrl}
                  placeholder='Youtube URL e.g. https://www.youtube.com/watch?v=LSq6vdYyNFI'
                  value={youtubeUrl}
                />
              </Column>
            </Row>
          </div>

          {this.state.sectionsData.map((sectionData, i) =>
            <LyricSectionEditor
              key={i}
              sectionData={sectionData}
              onChange={data => this.handleChange(data, i)}
            />
          )}

          <Button type='button' onClick={this.addSection}>Add Section</Button>
          <div className='editor-controls right'>
            <Group>
              Song Key:
              <Select onChange={this.selectOriginalKey} value={this.state.originalKey}>
                  {MUSICAL_KEYS.map((key, i) => <option key={i} value={key}>{key}</option>)}
              </Select>
              <Button type='submit'>Save Lyrics</Button>
            </Group>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(state => state, { createNewLyric, fetchLyricById, updateLyricById })(Editor);
