import React from 'react';
import { Text } from "rebass";
import styled from 'styled-components';

export const StyledLead = styled(Text)`
  line-height: 1.25;
  font-size: ${props => props.theme.fontSizes[3]}px;
`;

export const Lead = props =>
  <StyledLead
    {...props}
  />
