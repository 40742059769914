import React from 'react';
import { Fixed } from './Fixed';

const Loader = props => (
  <div>
    { props.loading &&
        <Fixed
          top={0}
          right={0}
          bottom={0}
          left={0}
          className='loading-bg'
        >
          <div className='loader'></div>
        </Fixed>
        }
  </div>
);

export default Loader;
