import React from 'react';
import { Flex } from 'rebass';

const radius = '4px';
export const Group = props =>
  <Flex
    {...props}
    css={{
      '& > *': {
        borderRadius: 0
      },
      '& > *:first-child': {
        borderRadius: `${radius} 0 0 ${radius}`
  
      },
      '& > *:last-child': {
        borderRadius: `0 ${radius} ${radius} 0`
      },
    }}
  />
