import { FETCH_LYRIC_BY_ID_SUCCESS, FETCH_LYRIC_BY_ID, FETCH_LYRIC_BY_ID_FAILURE, UPDATE_LYRIC_BY_ID, UPDATE_LYRIC_BY_ID_FAILURE, FETCH_LYRIC_META_SUCCESS } from "../actions/lyric";
import { CREATE_NEW_LYRIC, CREATE_NEW_LYRIC_FAILURE } from "../actions/lyrics";

export const INITIAL_STATE = {
  data: null,
  loading: false,
  meta: null
};

export function lyricReducer(state = INITIAL_STATE, action) {
  const { payload } = action;

  switch(action.type) {
    case FETCH_LYRIC_BY_ID:
    case UPDATE_LYRIC_BY_ID:
    case CREATE_NEW_LYRIC:
      return { ...state, loading: true };
    case FETCH_LYRIC_BY_ID_SUCCESS:
      return { ...state, loading: false, data: payload.data, meta: null };
    case FETCH_LYRIC_BY_ID_FAILURE:
    case UPDATE_LYRIC_BY_ID_FAILURE:
    case CREATE_NEW_LYRIC_FAILURE:
      return { ...state, loading: false };
    case FETCH_LYRIC_META_SUCCESS:
      return { ...state, meta: payload };
    default:
      return state;
  }
}
