import React from 'react';
import { Fixed } from "./Fixed";

const darken = 'rgba(0,0,0,0.5)';
export const Modal = props =>
  <Fixed
    p={3}
    style={{
      top: '50%',
      left: '50%',
      bg: 'white',
      borderRadius: 2,
      maxWidth: '100vw',
      maxHeight: '100vh',
      boxShadow: `0 0 0 60vmax ${darken}, 0 0 32px ${darken}`,
      overflow: 'auto',
      transform: 'translate(-50%, -50%)'
    }}
  />
