import { TOGGLE_LOGIN_MODAL, CHECK_AUTH_SUCCESS, CHECK_AUTH_FAILURE } from "../actions/auth";

export const INITIAL_STATE = {
  user: null,
  showLogin: false
};

export function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHECK_AUTH_SUCCESS:
      return { ...state, showLogin: false, user: action.payload };
    case CHECK_AUTH_FAILURE:
      return { ...state, showLogin: false, user: null };
    case TOGGLE_LOGIN_MODAL:
      return { ...state, showLogin: !state.showLogin };
    default:
      return state;
  }
};
