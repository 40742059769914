import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, Link } from 'react-router-dom';
import classnames from 'classnames';
import { getViewerDOMStringForLyricSection, MUSICAL_KEYS, getTransposeKeysList } from '../utils';
import { fetchLyricById } from '../actions/lyric';
import Loader from '../components/Loader';
import { Card, Flex, Box, Heading, Link as RebassLink, Image } from 'rebass';
import { RouteComponentProps } from 'react-router';
import { Container } from '../components/Container';
import { Row } from '../components/Row';
import { Column } from '../components/Column';
import { Select } from '../components/Select';
import { Caps } from '../components/Caps';
import { MusicalKey, LyricalData } from '../interfaces';
import Jungle from '../libs/jungle';

interface ViewerParams {
  lyricId: string;
}

interface OwnProps {}
interface StateProps {
  lyric;
  auth;
}
interface DispatchProps {
  fetchLyricById;
}

type ViewerProps = RouteComponentProps<ViewerParams> & DispatchProps & StateProps & OwnProps;

interface ViewerState {
  sections: any[];
  sectionsDOMs: any[];
  originalKey: MusicalKey;
  transposeInterval: number;
  songTitle: string;
  albumTitle: string;
  bpm: string;
  artists: any[];
  year: string;
  tags: any[];
  youtubeUrl: string;
  lyricId: string;
  viewerMode: string;
  lastfmMeta: any;
}
class Viewer extends Component<ViewerProps, ViewerState> {
  audioEl: HTMLAudioElement;
  audioContext: AudioContext;
  audioSource: MediaElementAudioSourceNode;
  audioJungle;

  constructor(props) {
    super(props);
    const { match: { params } } = props;

    /**
     * @type Lyric
     */
    let lyricalData = JSON.parse(localStorage.getItem('lyricaldata')); 

    if (params.lyricId) {
      lyricalData = null;
    }

    this.state = {
      sections: [],
      sectionsDOMs: lyricalData ? this.getSectionsDOMS(lyricalData.sections, lyricalData.key) : [],
      originalKey: 'C',
      transposeInterval: -1,
      songTitle: '',
      albumTitle: '',
      bpm: '',
      artists: [],
      year: '',
      tags: [],
      youtubeUrl: '',
      lyricId: params.lyricId,
      viewerMode: 'lyrics',
      lastfmMeta: null,
    };

    if ('AudioContext' in window) {
      this.audioContext = new AudioContext();
    }
  }

  componentDidMount() {
    const { lyric, match: { params } } = this.props;

    if (params.lyricId) {
      this.props.fetchLyricById(params.lyricId);
    }

    if (lyric.data) {
      this.loadLyric(lyric.data);
    }

    this.setupAudioMixer();
  }
  componentWillReceiveProps(newProps) {
    // Set the internal state if the lyric state changes
    if (newProps.lyric.data && JSON.stringify(newProps.lyric.data) !== JSON.stringify(this.props.lyric.data || {})) {
      const lyricalData = newProps.lyric.data;

      this.loadLyric(lyricalData);
    }
  }

  componentDidUpdate() {
    this.setupAudioMixer();
  }

  loadLyric = ({ data, lastfmMeta }: { data: LyricalData, lastfmMeta: any }) => {
    console.log(data, lastfmMeta);
    this.setState({
      originalKey: data.key,
      songTitle: data.title,
      albumTitle: data.album,
      bpm: data.bpm,
      artists: data.artists,
      year: data.year,
      tags: data.tags,
      sections: data.sections,
      sectionsDOMs: this.getSectionsDOMS(data.sections, 0),
      youtubeUrl: data.youtubeUrl,
      lastfmMeta,
    });
  };

  setupAudioMixer() {
    console.log(this.audioEl, this.audioContext);
    if (this.audioContext && this.audioEl) {
      if (!this.audioSource) {
        this.audioSource = this.audioContext.createMediaElementSource(this.audioEl);
      }
      if (!this.audioJungle) {
        this.audioJungle = new Jungle(this.audioContext);
      }

      this.audioSource.connect(this.audioJungle.input);
      this.audioJungle.output.connect(this.audioContext.destination);
      if (this.state.transposeInterval > -1) {
        this.audioJungle.setPitchTranspose(0, this.state.transposeInterval);
      } else {
        this.audioJungle.setPitchTranspose(0, 0);
      }
    }
  }

  getSectionsDOMS = (sections, transposeInterval) => sections.map(section => ({
    title: section.title,
    html: getViewerDOMStringForLyricSection(section, {
      songKey: this.state.originalKey,
      transposeInterval: transposeInterval < 0 ? 0 : transposeInterval,
      showNash: transposeInterval < -1
    })
  }));

  selectTransposeInterval = evt => this.setState({
    transposeInterval: evt.target.value,
    sectionsDOMs: this.getSectionsDOMS(this.state.sections, evt.target.value)
  });
  selectViewerMode = evt => this.setState({ viewerMode: evt.target.value });

  render() {
    const { lyric, auth } = this.props;
    const { youtubeUrl } = this.state;

    let canEdit = false;
    let albumArt = '';

    if (lyric.data) {
      canEdit = auth.user && auth.user.uid && lyric.data.roles && lyric.data.roles[auth.user.uid] === 'creator';

      if (lyric.data.lastfmMeta) {
        albumArt = lyric.data.lastfmMeta.images && lyric.data.lastfmMeta.images[lyric.data.lastfmMeta.images.length - 1];
      }
    }


    return (
      <div className={classnames('viewer-screen', { 'chords-mode': this.state.viewerMode === 'chords' })}>
        <Loader loading={lyric.loading} />
        <Container>
          <Row flexWrap='wrap'>
            <Column width={[1, 2/5]}>
              <Card p={20}>
                <Heading mb={20}>{this.state.songTitle}</Heading>
                <Image
                  mb={20}
                  width='100%'
                  src={albumArt}
                />
                <Box>
                  Song Key:
                  <Select onChange={this.selectTransposeInterval} value={this.state.transposeInterval} mb={20}>
                    <option value={-1}>Original Key</option>
                    {getTransposeKeysList(this.state.originalKey).map((ti, i) => <option key={i} value={ti.interval}>{ti.key}</option>)}
                    <option value={-2}>Nashville</option>
                  </Select>
                </Box>
                <Caps mb={20}>Artists: {this.state.artists}</Caps>
                <Caps mb={20}>Original Key: {this.state.originalKey}</Caps>
                <Caps mb={20}>Year: {this.state.year}</Caps>
                <Flex alignItems='center'>
                  <Select onChange={this.selectViewerMode} value={this.state.viewerMode} mb={20}>
                    <option value='lyrics'>Lyrics Mode</option>
                    <option value='chords'>Chords Mode</option>
                  </Select>
                </Flex>
                {youtubeUrl &&
                  <Flex>
                    <audio crossOrigin='anonymous' ref={el => this.audioEl = el} controls src={`https://audio-stream.samuelimolo4real.now.sh/audio-stream?url=${encodeURIComponent(youtubeUrl)}`}>
                      <p>Browser too old to support HTML5 audio? How depressing!</p>
                    </audio>
                  </Flex>
                }
                { canEdit &&
                  <Flex justifyContent='space-between'>
                    <Box>
                      <RouterLink to={`/lyric/${this.state.lyricId}/edit`}>Edit</RouterLink>
                    </Box>
                    <Box>
                      {/* <RebassLink>Delete</RebassLink> */}
                    </Box>
                  </Flex>
                }
              </Card>
            </Column>
            <Column width={[1, 3/5]}>
              {this.state.sectionsDOMs.map((sectionDOM, i) =>
                <div key={i} className='viewer-section'>
                  <div className='viewer-section-title' dangerouslySetInnerHTML={{ __html: sectionDOM.title }}></div>
                  <div className='viewer-section-wrapper' dangerouslySetInnerHTML={{ __html: sectionDOM.html }}></div>
                </div>
              )}
            </Column>
          </Row>
        </Container>
      </div>
    );
  }
}

export default connect(state => state, { fetchLyricById })(Viewer);
