// import { theme as defaultTheme, createColors } from 'rebass';

// const theme = defaultTheme;
// const colors = createColors('#118DF0');

// theme.colors = colors;

const blue = '#118DF0';
const lightgray = '#f6f6ff';

const theme = {
  fontSizes: [
    12, 14, 16, 20, 24, 32, 48, 64
  ],
  colors: {
    blue,
    lightgray,
  },
  buttons: {
    primary: {
      color: '#fff',
      backgroundColor: blue,
    },
    outline: {
      color: blue,
      backgroundColor: 'transparent',
      boxShadow: 'inset 0 0 2px'
    },
  },
};

export { theme };