import React from 'react';
import { Link } from 'react-router-dom';

export const NavLink = ({ to = null, ...props }) => {

  if (!to) {
    return <span className='app-nav-link' {...props}/>
  }
  return <Link
    to={to}
    style={{
      color: 'inherit',
      textDecoration: 'none',
      fontSize: 14,
      padding: 8,
    }}
    {...props}
  />
};
