import React from 'react';
import { Box } from "rebass";

export const Fixed = props =>
  <Box
    {...props}
    css={{
      position: 'fixed'
    }}
  />
