import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchLyrics } from '../actions/lyrics';
import LyricItemCard from '../components/LyricItemCard';
import Loader from '../components/Loader';
import { Container } from '../components/Container';

interface OwnProps {}
interface StateProps {
  lyrics;
  auth;
}
interface DispatchProps {
  fetchLyrics;
}

type LyricsListProps = StateProps & DispatchProps & OwnProps;

class LyricsList extends Component<LyricsListProps> {
  componentDidMount() {
    this.props.fetchLyrics();
  }
  componentWillReceiveProps(newProps) {
    if(newProps.auth.user !== this.props.auth.user) {
      this.props.fetchLyrics();
    }
  }
  render() {
    const {
      lyrics
    } = this.props;

    return (
      <div className='list-screen'>
        <Loader loading={lyrics.loading} />
        <Container>
          {lyrics.items.map(((lyric, i) => <LyricItemCard key={i} lyric={lyric} mb={20}/>))}
        </Container>
      </div>
    );
  }
}

export default connect(state => state, { fetchLyrics })(LyricsList);
