import { takeEvery, all, takeLatest, put, call } from 'redux-saga/effects';
import { firebaseApp, db } from '../config/firebase';
import { lastfm } from '../config/lastfm';
import { CHECK_AUTH, SIGNIN, SIGNUP, checkAuthSuccess, checkAuthFailure, LOGOUT } from '../actions/auth';
import { toast } from 'react-toastify';
import { CREATE_NEW_LYRIC, createNewLyricSuccess, createNewLyricFailure, FETCH_LYRICS, fetchLyricsFailure, fetchLyricsSuccess, fetchLyricsLoading } from '../actions/lyrics';
import { push } from 'connected-react-router';
import { fetchLyricByIdSuccess, fetchLyricByIdFailure, FETCH_LYRIC_BY_ID, fetchLyricMeta, updateLyricByIdSuccess, updateLyricByIdFailure, UPDATE_LYRIC_BY_ID } from '../actions/lyric';

export function* checkAuth(opts: any = { redirectUrl: '' }) {
  const redirectUrl = opts.redirectUrl;
  const checkFBAuth = new Promise((resolve, reject) => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        // User is signed in.
        resolve(user);
      } else {
        // No user is signed in.
        reject('No user!');
      }
    });
  });

  try {
    const user = yield checkFBAuth;

    yield put(checkAuthSuccess(user));
    if (opts.redirectUrl) {
      yield put(push(opts.redirectUrl));
    }
  } catch (err) {
    yield put(checkAuthFailure());
  }
}

export function* createAccount(action) {
  const { email, password, displayName } = action.payload;
  try {
    const user = yield call(() => firebaseApp.auth().createUserWithEmailAndPassword(email, password));
    yield call(() => firebaseApp.auth().currentUser.updateProfile({
      displayName,
      photoURL: null
    }));

    yield call(checkAuth, { redirectUrl: '/' });
  } catch (err) {
    toast.error('Something went wrong while signing up. Check your credentials.');
    console.log('Signup error: ' + JSON.stringify(err.message));
  }
}

export function* loginUser(action) {
  const { email, password } = action.payload;
  try {
    const credentials = yield call(() => firebaseApp.auth().signInWithEmailAndPassword(email, password));

    if (credentials) {
      // console.log(JSON.stringify(credentials.user.toJSON()));
      yield call(checkAuth, { redirectUrl: '/' });
    }
  } catch (err) {
    toast.error('Check your credentials and try again.');
    console.error('Signin error: ' + JSON.stringify(err.message));
  }
}

export function* logoutUser() {
  try {
    const signout = yield call(() => firebaseApp.auth().signOut());
    yield call(checkAuth);
  } catch (err) {
    console.error('Signout error: ' + JSON.stringify(err.message));
  }
}

export function* createNewLyric(action) {
  const { data } = action.payload;

  try {
    const lastfmMeta = data.title ? yield lastfm.trackInfo({
      name: data.title,
      artistName: data.artists.join(','),
    }) : null;
    yield db.collection('lyrics').add({
      data,
      lastfmMeta,
      roles: {
        [firebaseApp.auth().currentUser.uid]: 'creator'
      }
    });
    yield put(createNewLyricSuccess());
    yield put(push('/'));
  } catch(err) {
    yield put(createNewLyricFailure());
    console.error(err);
  }
}

export function* updateLyricById(action) {
  const { data, lastfmMeta: prevLastfnMeta, id } = action.payload;

  try {
    const lastfmMeta = (!prevLastfnMeta && data.title) ? yield lastfm.trackInfo({
      name: data.title,
      artistName: data.artists.join(','),
    }) : prevLastfnMeta;
    yield db.collection('lyrics').doc(id).set({
      data,
      lastfmMeta,
      roles: {
        [firebaseApp.auth().currentUser.uid]: 'creator'
      }
    });
    toast.success('Updated lyric successfully.');
    yield put(updateLyricByIdSuccess());
    yield put(push(`/lyric/${id}`));
  } catch(err) {
    toast.error('Something went wrong. Check that you have permission.');
    yield put(updateLyricByIdFailure());
    console.error(err);
  }
}

export function* fetchLyrics() {
  try {
    yield put(fetchLyricsLoading());
    const snapshot = yield db.collection('lyrics').get();

    const lyrics = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    yield put(fetchLyricsSuccess({ lyrics }));
  } catch(err) {
    yield put(fetchLyricsFailure());
    console.error(err);
  }
}

export function* fetchLyricById(action) {
  try {
    const doc = yield db.collection('lyrics').doc(action.payload).get();
    const lyric = { ...doc.data(), id: doc.id };
    if (doc.exists) {
      yield put(fetchLyricByIdSuccess({ data: lyric }));
      // yield put(fetchLyricMeta(lyric));
    } else {
      yield put(fetchLyricByIdFailure());
    }
  } catch(err) {
    yield put(fetchLyricByIdFailure());
    console.error(err);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(CHECK_AUTH, checkAuth),
    takeLatest(SIGNIN, loginUser),
    takeLatest(SIGNUP, createAccount),
    takeLatest(LOGOUT, logoutUser),

    takeLatest(CREATE_NEW_LYRIC, createNewLyric),
    takeLatest(FETCH_LYRICS, fetchLyrics),
    takeLatest(FETCH_LYRIC_BY_ID, fetchLyricById),

    takeLatest(UPDATE_LYRIC_BY_ID, updateLyricById),
  ])
}
