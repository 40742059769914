import React from 'react';
import { Flex } from "rebass";

export const Column = props =>
  <Flex
    px={3}
    mb={4}
    flex='1 1 auto'
    flexDirection='column'
    {...props}
  />
