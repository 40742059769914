import React from 'react';
import { Flex } from 'rebass';
export const Toolbar = props =>
  <Flex
    px={2}
    color='white'
    bg='black'
    alignItems='center'
    {...props}
  />
