import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './App.css';
// import { getSelectedContent, setBlock } from './utils';
import Editor from './screens/Editor';
import Viewer from './screens/Viewer';
import NavHeader from './components/NavHeader';
import LoginModal from './components/LoginModal';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { store, history } from './store';
import { ConnectedRouter } from 'connected-react-router';
import { checkAuth } from './actions/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LyricsList from './screens/LyricsList';
import LoginScreen from './screens/Auth';
import PrivateRoute from './guards/PrivateRoute';

class App extends Component {
  componentDidMount() {
    store.dispatch(checkAuth());
  }
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={theme}>
            <div className='app-wrapper'>
              <NavHeader />
              <LoginModal />
              <ToastContainer />
              <div className='app-main'>
                <Route exact path='/login' component={LoginScreen} />
                <Route exact path='/lyric/:lyricId' component={Viewer} />
                <PrivateRoute>
                  <Route exact path='/' component={LyricsList} />
                  <Route path='/editor' component={Editor} />
                  <Route path='/viewer' component={Viewer} />
                  <Route exact path='/new-lyric' component={Editor} />
                  <Route exact path='/lyric/:lyricId/edit' component={Editor} />
                </PrivateRoute>
              </div>
            </div>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

/*
https://www.lotusmusic.com/lm_chordnames.html
https://www.wikiwand.com/en/Chord_(music)
https://www.edmprod.com/different-chord-types/
https://www.guitar-chord.org/chord-types.html


https://github.com/mvanlonden/react-selection-popover/blob/master/src/index.js
https://draftjs.org/docs/advanced-topics-decorators.html

https://developer.mozilla.org/en-US/docs/Web/API/Selection
https://jsfiddle.net/2C6fB/1/
https://stackoverflow.com/questions/845390/javascript-to-get-paragraph-of-selected-text-in-web-page

https://canjs.com/doc/guides/recipes/text-editor.html


Save format:
{
  key: 'c',
  lines: [
    {
      blocks: [
        {
          text: 'creat',
          key: 1,
          chord: 'major', // major, minor, diminished, half-diminished, dominant
          qualifier: '', // none, 6, 7, 9, 11, 13
        }
      ]
    }
  ]
}

Open for editing:
<span data-key="1" data-chord="major" data-qualifier="6">creat</span>


<some part|c|M|>
Requires:
>IE9
window.getSelection
*/
export default App;
