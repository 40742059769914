import { FETCH_LYRICS_SUCCESS, FETCH_LYRICS_LOADING, FETCH_LYRICS_FAILURE } from "../actions/lyrics";

const INITIAL_STATE = {
  items: [],
  loading: false
};

export function lyricsReducer(state = INITIAL_STATE, action) {
  const { payload } = action;
  switch(action.type) {
    case FETCH_LYRICS_LOADING:
      return { ...state, loading: true };
    case FETCH_LYRICS_SUCCESS:
      return { ...state, items: payload.lyrics, loading: false };
    case FETCH_LYRICS_FAILURE:
      return { ...state, loading: false, items: INITIAL_STATE.items };
    default:
      return state;
  }
}
