import { createAction } from '../actions';

export const CREATE_NEW_LYRIC = Symbol('CREATE_NEW_LYRIC');
export const CREATE_NEW_LYRIC_SUCCESS = Symbol('CREATE_NEW_LYRIC_SUCCESS');
export const CREATE_NEW_LYRIC_FAILURE = Symbol('CREATE_NEW_LYRIC_FAILURE');

export const FETCH_LYRICS = Symbol('FETCH_LYRICS');
export const FETCH_LYRICS_LOADING = Symbol('FETCH_LYRICS_LOADING');
export const FETCH_LYRICS_SUCCESS = Symbol('FETCH_LYRICS_SUCCESS');
export const FETCH_LYRICS_FAILURE = Symbol('FETCH_LYRICS_FAILURE');

export const createNewLyric = createAction(CREATE_NEW_LYRIC);
export const createNewLyricSuccess = createAction(CREATE_NEW_LYRIC_SUCCESS);
export const createNewLyricFailure = createAction(CREATE_NEW_LYRIC_FAILURE);

export const fetchLyrics = createAction(FETCH_LYRICS);
export const fetchLyricsLoading = createAction(FETCH_LYRICS_LOADING);
export const fetchLyricsSuccess = createAction(FETCH_LYRICS_SUCCESS);
export const fetchLyricsFailure = createAction(FETCH_LYRICS_FAILURE);
