import {
    Block,
    Line,
    LyricSection,
    NashKey,
    MusicalKey,
    ChordType,
    ChordQualifier
} from "./interfaces";
import { TextJSON, InlineJSON, BlockJSON, Value } from "slate";


export const NASH_KEYS: NashKey[] = [ '1', '1#', '2', '2#', '3', '4', '4#', '5', '5#', '6', '6#', '7' ];
export const MUSICAL_KEYS: MusicalKey[] = [ 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B' ];
export const CHORD_TYPES: ChordType[] = [ 'major', 'minor', 'sus2', 'sus4' ];
export const CHORD_QUALIFIERS: ChordQualifier[] = [ '', '6', '7', '9', '11', '13' ];


const ELEMENT_NODE = 1;
const TEXT_NODE = 3;
const COMMENT_NODE = 8;

export const getSelectedContent = () => {
    
    if (!window.getSelection) {
        return '';
    }
    let selection = window.getSelection();

    console.log(selection, selection.toString());
    let parent = selection.anchorNode;
    // parent = parent.parentNode;
    // while (parent != null && parent.localName != "P") {
    //   parent = parent.parentNode;
    // }
    if (parent == null) {
      return '';
    } else {
        //   return parent.innerText || parent.textContent;
        return parent.textContent;
    }
};

/**
 * Returns a succinct name for the chord in a block
 * @param {Block} block
 */
export const getChordName = (block: Block) => {
    if (!block.key) {
        return '';
    }
    const parts = [ block.key ];

    if (block.chord) {
        switch(block.chord) {
            case 'major':
                // For major chords, we don't need to add the chord.
                break;
            case 'minor':
                parts.push('m');
                break;
            case 'sus2':
                parts.push('sus2');
                break;
            case 'sus4':
                parts.push('sus4');
                break;
        }
    }

    if (block.qualifier) {
        parts.push(block.qualifier);
    }

    if (block.slashKey) {
        parts.push('/' + block.slashKey);
    }

    return parts.join('');
};

export const getAttr = (name: string, data: string) => data ? `data-lyric-${name}="${data}"` : '';

export const getBlockAttrs = (block: Block) => block.key ?
    `
        class="editor-block"
        contenteditable="false"
        ${getAttr('key', block.key)}
        ${getAttr('chord', block.chord)}
        ${getAttr('qualifier', block.qualifier)}
        ${getAttr('slash-key', block.slashKey)}
        ${getAttr('display-name', getChordName(block))}
        title="${block.key}${block.chord}${block.qualifier}${block.slashKey}"
    ` : ``;

export const getViewerBlockAttrs = (block, opts) => {
    if (block.key) {
        const blk = { ...block, key: getTransposedKey(block.key, opts) };
        return `
            class="viewer-block"
            ${getAttr('key', blk.key)}
            ${getAttr('chord', blk.chord)}
            ${getAttr('qualifier', blk.qualifier)}
            ${getAttr('slash-key', blk.slashKey)}
            ${getAttr('display-name', getChordName(blk))}
        `
    }
    else return ``;
}

/**
 * Returns DOM string equivalent of block
 * @param {Block} block
 */
export const getBlockDOMString =
    (block: Block) =>
        `<span ${getBlockAttrs(block)}>${block.text}</span>`;

/**
 * Returns DOM string equivalent of block
 * @param {Block} block
 */
export const getViewerBlockDOMString =
    (block: Block, opts) =>
        `<span ${getViewerBlockAttrs(block, opts)}>${block.text}</span>`;

/**
 * Returns DOM string equivalent of line
 * @param {Line} line
 */
export const getLineDOMString = (line: Line, opts) => {
    const strBlocks = opts && opts.isViewing ?
        line.blocks.map(block => getViewerBlockDOMString(block, opts)) :
        line.blocks.map(getBlockDOMString);
    
    return [
        '<div>',
        ...strBlocks,
        '&nbsp;</div>' // add extra space at the end of the div to make editing easier
    ].join('');
}

export const getSelectionRange = () => {
    if (window.getSelection) {
        const sel = window.getSelection();

        if (sel.getRangeAt && sel.rangeCount) {
            return window.getSelection().getRangeAt(0);
        }
    }

    return null;
};

export const setDOMBlock = ({ key = 'C', chord = 'major', slashKey = '' }) => {
    var node;
    const range = getSelectionRange();
    if (range) {
        const html = getBlockDOMString({ key, chord, slashKey, text: range.extractContents().textContent, qualifier: '' });
        range.deleteContents();

        var el = document.createElement("div");
        el.innerHTML = html;
        var frag = document.createDocumentFragment(), node, lastNode;
        while ( (node = el.firstChild) ) {
            lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Trigger synthetic input event after updating DOM to force onChange event to be fired.
        let editorViewerNode = range.commonAncestorContainer;
        let editorViewer: HTMLElement;

        if (editorViewerNode.nodeType !== ELEMENT_NODE) {
            editorViewer = editorViewer.parentElement;
        } else {
            editorViewer = <HTMLElement>editorViewerNode;
        }
        editorViewer.closest('.js-editor-viewer').dispatchEvent(new Event('input', {
            'bubbles': true,
            'cancelable': true
        }));
    }
};

/**
 * Checks if the selection on the page is in an element
 * @param {Element} element
 */
export const isSelectionInElement = (element: Element) => {
    const range = getSelectionRange();

    if (element && range) {
        return element.contains(range.commonAncestorContainer);
    }
    return false;
};

/**
 * Checks if the selection on the page is in a selector
 * @param {string} selector
 */
export const isSelectionInSelector = (selector: string) => {
    const range = getSelectionRange();

    if (selector && range) {
        return range.commonAncestorContainer.parentElement.closest(selector);
    }
    return false;
}

/**
 * 
 * @param {Node} node 
 */
export const getBlocksFromDOM = (node: Node) => {
    /**
     * @type Array.<Block>
     */
    let blocks = [];
    if (node.nodeType === ELEMENT_NODE) {
        let currentChild = node.firstChild;
        while (currentChild) {
            if (currentChild.nodeType === TEXT_NODE) {
                blocks = [ ...blocks, { text: currentChild.textContent} ]; // , node: currentChild
            } else if (currentChild.nodeType === ELEMENT_NODE) {
                const elementChild = <HTMLElement>currentChild;
                if (elementChild.nodeName === 'SPAN') {
                    if (elementChild.children.length) {
                        blocks = [ ...blocks, ...getBlocksFromDOM(elementChild) ];
                    } else {
                         /**
                         * @type Block
                         */
                        const curBlock: any = { text: elementChild.textContent }; // , node: elementChild
                        if (elementChild.getAttribute('data-key')) {
                            curBlock.key = elementChild.getAttribute('data-key');
                        }
                        if (elementChild.getAttribute('data-chord')) {
                            curBlock.chord = elementChild.getAttribute('data-chord');
                        }
                        if (elementChild.getAttribute('data-qualifier')) {
                            curBlock.qualifier = elementChild.getAttribute('data-qualifier');
                        }
                        if (elementChild.getAttribute('data-slash-key')) {
                            curBlock.slashKey = elementChild.getAttribute('data-slash-key');
                        }

                        blocks = [ ...blocks, curBlock ];
                    }
                } else {
                    // Handle div, p, block element cases
                    blocks = [ ...blocks, { text: '<SPLIT>' }, ...getBlocksFromDOM(elementChild) ];
                }
            }
            currentChild = <ChildNode>currentChild.nextSibling;
        }
    }

    return blocks;
};

export const getLinesFromDOM = (node: Node) => {
    /**
     * @type Array.<Line>
     */
    let lines = [];
    let lineIndex = 0;

    const blocks = getBlocksFromDOM(node);

    blocks.forEach((block) => {
        lines[lineIndex] = lines[lineIndex] || { blocks: [] };
        if (block.text === '<SPLIT>') {
            if (lines[lineIndex].blocks.length && !lines[lineIndex].blocks[lines[lineIndex].blocks.length - 1].text.replace(/\s*/, '')) {
                // Trim off the last block if its content is only spaces
                lines[lineIndex].blocks.pop();
            }
            lineIndex++;
            return;
        }
        lines[lineIndex].blocks.push(block);
    });

    // Filter out empty lines
    lines = lines.filter(line => line && line.blocks && line.blocks.length);

    return lines;
};

/**
 * 
 * @param {Node} node
 * @deprecated
 */
export const getLinesFromDOM_Old = node => {
    /**
     * @type Array.<Line>
     */
    let lines = [];
    let lineIndex = 0;

    if (node.nodeType === ELEMENT_NODE) {
        let currentChild = node.firstChild;
        while(currentChild) {
            if (currentChild.nodeType === TEXT_NODE) {
                lines[lineIndex] = lines[lineIndex] || { blocks: [] };
                lines[lineIndex].blocks = [ ...lines[lineIndex].blocks, { text: currentChild.textContent, node: currentChild } ];
            } else if (currentChild.nodeType === ELEMENT_NODE && currentChild.nodeName === 'SPAN') {
                lines[lineIndex] = lines[lineIndex] || { blocks: [] };

                /**
                 * @type Block
                 */
                const curBlock: any = { text: currentChild.textContent, node: currentChild };
                if (currentChild.getAttribute('data-key')) {
                    curBlock.key = currentChild.getAttribute('data-key');
                }
                if (currentChild.getAttribute('data-chord')) {
                    curBlock.chord = currentChild.getAttribute('data-chord');
                }
                if (currentChild.getAttribute('data-qualifier')) {
                    curBlock.qualifier = currentChild.getAttribute('data-qualifier');
                }

                lines[lineIndex].blocks = [ ...lines[lineIndex].blocks, curBlock ];
            } else if (currentChild.nodeType === ELEMENT_NODE && currentChild.nodeName === 'DIV') {
                // Add line
                let newLines = getLinesFromDOM(currentChild);
                if (newLines.length) {
                    lines = lines.concat(newLines);
                }
                lineIndex += newLines.length;
            }
            currentChild = currentChild.nextSibling;
        }
    }

    return lines;
};

export const getDOMStringForLyricSection = (section: LyricSection, opts = {}) => {
    if (section && section.lines) {
        return section.lines.map(line => getLineDOMString(line, opts)).join('')
            // Extra div to make editing easier
            + '<div></div>';
    }

    return '';
};

export const getViewerDOMStringForLyricSection = (section: LyricSection, opts = { songKey: section.key, transposeInterval: 0, showNash: false }) => {
    return getDOMStringForLyricSection(section, { ...opts, isViewing: true });
};

/**
 * Given a key in nashville system, it returns the equivalent musical key
 * @param {string} nashKey Key in nashville system
 * @param {Object} opts
 */
export const getKeyFromNash = (nashKey, { songKey = 'C' }) => {

    let keyIdx = MUSICAL_KEYS.indexOf(<MusicalKey>songKey.toUpperCase());

    if (keyIdx < 0) {
        keyIdx = 0;
    }
    const _keys = rotateArray(MUSICAL_KEYS, keyIdx);

    return _keys[NASH_KEYS.indexOf(nashKey)];
};

/**
 * Given a musical key and song key, it returns the equivalent key in nashville system
 */
export const getNashFromKey = (key: MusicalKey, { songKey = 'C' }) => {
    let keyIdx = MUSICAL_KEYS.indexOf(<MusicalKey>songKey.toUpperCase());

    if (keyIdx < 0) {
        keyIdx = 0;
    }
    const _keys = rotateArray(MUSICAL_KEYS, keyIdx);

    return NASH_KEYS[_keys.indexOf(key.toUpperCase())];
};

export const rotateArray = (arr = [], n: number) => {
    const L = arr.length;
    return arr.slice(n, L).concat(arr.slice(0, n));
};

export const getTransposeKeysList = (originalKey: MusicalKey) => {
    let keyIdx = MUSICAL_KEYS.indexOf(<MusicalKey>originalKey.toUpperCase());
    return rotateArray(MUSICAL_KEYS, keyIdx).map((key, i) => ({ key, interval: i }));
};

export const getTransposedKey = (key: MusicalKey, { transposeInterval = 0, showNash, songKey }) => {
    if (showNash) {
        return getNashFromKey(key, { songKey });
    }
    const keyIdx = MUSICAL_KEYS.indexOf(<MusicalKey>key.toUpperCase());
    const transposedArr = rotateArray(MUSICAL_KEYS, transposeInterval);
    return transposedArr[keyIdx];
};


export const LYRIC_BLOCK_INLINE_TYPE = 'lyricBlock';
export const LYRIC_LINE_BLOCK_TYPE = 'lyricLine';
export const lyricSectionToSlateValue = (section: LyricSection) => {
  const lyricBlockToSlateNode = (block): TextJSON | InlineJSON => {
    if (block.key) {
      return {
        data: block,
        object: 'inline',
        type: LYRIC_BLOCK_INLINE_TYPE,
        nodes: [{
          object: 'text',
          text: block.text
        }]
      };
    }

    return {
      object: 'text',
      text: block.text,
    };
  };
  const lyricLineToSlateNode = (line): BlockJSON => ({
    object: 'block',
    type: LYRIC_LINE_BLOCK_TYPE,
    nodes: line.blocks.map(lyricBlockToSlateNode)
  });

  return Value.fromJSON({
    document: {
      nodes: section.lines.map(lyricLineToSlateNode)
    }
  });
};

export const slateValueToLyricLines = (slateValue: Value) => {
    return slateValue.toJSON({
      preserveData: true,
    }).document.nodes.map(node => {
      if (node.object === 'block' && node.nodes.length) {
        return {
          blocks: node.nodes.map(inlineNode => {
            if (inlineNode.object === 'inline' && inlineNode.type === LYRIC_BLOCK_INLINE_TYPE) {
              return {
                ...inlineNode.data,
                text: inlineNode.nodes.map(textNode => textNode.object === 'text' && textNode.text).join(''),
              };
            }
            if (inlineNode.object === 'text' && inlineNode.text) {
              return {
                text: inlineNode.text
              };
            }
          }).filter(Boolean),
        };
      }
      return null;
    }).filter(Boolean);
  };