import { createAction } from '.';

export const FETCH_LYRIC_BY_ID = Symbol('FETCH_LYRIC_BY_ID');
export const FETCH_LYRIC_BY_ID_SUCCESS = Symbol('FETCH_LYRIC_BY_ID_SUCCESS');
export const FETCH_LYRIC_BY_ID_FAILURE = Symbol('FETCH_LYRIC_BY_ID_FAILURE');

export const FETCH_LYRIC_META = Symbol('FETCH_LYRIC_META');
export const FETCH_LYRIC_META_SUCCESS = Symbol('FETCH_LYRIC_META_SUCCESS');
export const FETCH_LYRIC_META_FAILURE = Symbol('FETCH_LYRIC_META_FAILURE');

export const UPDATE_LYRIC_BY_ID = Symbol('UPDATE_LYRIC_BY_ID');
export const UPDATE_LYRIC_BY_ID_SUCCESS = Symbol('UPDATE_LYRIC_BY_ID_SUCCESS');
export const UPDATE_LYRIC_BY_ID_FAILURE = Symbol('UPDATE_LYRIC_BY_ID_FAILURE');

export const fetchLyricById = createAction(FETCH_LYRIC_BY_ID);
export const fetchLyricByIdSuccess = createAction(FETCH_LYRIC_BY_ID_SUCCESS);
export const fetchLyricByIdFailure = createAction(FETCH_LYRIC_BY_ID_FAILURE);

export const fetchLyricMeta = createAction(FETCH_LYRIC_META);
export const fetchLyricMetaSuccess = createAction(FETCH_LYRIC_META_SUCCESS);
export const fetchLyricMetaFailure = createAction(FETCH_LYRIC_META_FAILURE);

export const updateLyricById = createAction(UPDATE_LYRIC_BY_ID);
export const updateLyricByIdSuccess = createAction(UPDATE_LYRIC_BY_ID_SUCCESS);
export const updateLyricByIdFailure = createAction(UPDATE_LYRIC_BY_ID_FAILURE);
