import firebase from 'firebase';

export const config = {
  apiKey: "AIzaSyAN3h1dOaPMRvpuk1dpJGE1zQolYZrAHf8",
  authDomain: "lyrical-34f56.firebaseapp.com",
  databaseURL: "https://lyrical-34f56.firebaseio.com",
  projectId: "lyrical-34f56",
  storageBucket: "lyrical-34f56.appspot.com",
  messagingSenderId: "853481072375"
};

export const firebaseApp = firebase.initializeApp(config);

export const db = firebaseApp.firestore();

db.settings({
  timestampsInSnapshots: true
});

db.enablePersistence();
