import { takeLatest, all, put } from "redux-saga/effects";
import { FETCH_LYRIC_META, fetchLyricMetaFailure, fetchLyricMetaSuccess } from "../actions/lyric";
import { lastfm } from "../config/lastfm";

export function* fetchLyricMeta(action) {
  const { payload } = action;

  if (!payload.data.title) {
    return;
  }

  try {
    const data = yield lastfm.trackInfo({
      name: payload.data.title,
      artistName: payload.data.artists.join(','),
    });
    yield put(fetchLyricMetaSuccess(data));
  } catch(err) {
    yield put(fetchLyricMetaFailure());
    console.log('Lyric meta error:', err);
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(FETCH_LYRIC_META, fetchLyricMeta),
  ]);
}
