import { all, call } from 'redux-saga/effects';
import firebaseSaga from './firebase';
import lastfmSaga from './lastfm';

export function* helloSaga() {
  console.log('Hello Sagas!')
}

export default function* rootSaga() {
  yield all([
    call(helloSaga),
    call(firebaseSaga),
    call(lastfmSaga),
  ]);
}
