import React, { Component } from 'react';
import { Editor, RenderInlineProps, EditorProps } from 'slate-react';
import { Input } from './Input';
import { Popper } from 'react-popper';
import { getChordName, MUSICAL_KEYS, CHORD_TYPES, LYRIC_BLOCK_INLINE_TYPE } from '../utils';
import { Button } from 'rebass';
import { Select } from './Select';

const PopperMenuContent: React.FC<{ editor: Editor }> = ({ editor }) => {
  const { value } = editor;
  let inlineData: any = {};
  const inlines = value.inlines.filter(inline => inline.type === LYRIC_BLOCK_INLINE_TYPE);
  if (inlines.count() === 1) {
    inlineData = inlines.first().data.toObject();
  } else {
    inlineData = {};
  }
  const setLyricBlock = (data) => {
    const wrapOperation = () => {
      const inline = value.inlines.find(inline => inline.type === LYRIC_BLOCK_INLINE_TYPE);
      if (inline) {
        return editor.setInlines({
          data: inline.data.merge(data),
          type: LYRIC_BLOCK_INLINE_TYPE,
        });
      }

      return editor.wrapInline({
        data,
        type: LYRIC_BLOCK_INLINE_TYPE
      });
    };
    return wrapOperation()
      .moveToStartOfInline()
      .moveFocusToEndOfInline()
      .focus();
  };
  const onSelectKey = (evt) => {
    return setLyricBlock({
      key: evt.target.value
    });
  };

  const onSelectChord = (evt) => {
    return setLyricBlock({
      chord: evt.target.value
    });
  };

  const onSelectSlashKey = (evt) => {
    return setLyricBlock({
      slashKey: evt.target.value
    });
  };
  const onClearBlock = () => {
    return editor.unwrapInline(LYRIC_BLOCK_INLINE_TYPE);
  };
  return (
    <div className="editor-popper-menu__inner">
      <div className="editor-popper-menu__column">
        <div className="editor-popper-menu__column-title">Key</div>
        <Select
          className='editor-popper-menu__control'
          style={{ marginBottom: 0 }}
          onChange={onSelectKey}
          value={inlineData.key || ''}
        >
          <option>-</option>
          {MUSICAL_KEYS.map((key, i) => <option key={i} value={key}>{key}</option>)}
        </Select>
      </div>
      <div className="editor-popper-menu__column">
        <div className="editor-popper-menu__column-title">Chord</div>
        <Select
          className='editor-popper-menu__control'
          style={{ marginBottom: 0 }}
          onChange={onSelectChord}
          value={inlineData.chord || ''}
        >
          <option>-</option>
          {CHORD_TYPES.map((chord, i) => <option key={i} value={chord}>{chord}</option>)}
        </Select>
      </div>
      <div className="editor-popper-menu__column">
        <div className="editor-popper-menu__column-title">Slash key</div>
        <Select
          className='editor-popper-menu__control'
          style={{ marginBottom: 0 }}
          onChange={onSelectSlashKey}
          value={inlineData.slashKey || ''}
        >
          <option value=''>-</option>
          {MUSICAL_KEYS.map((key, i) => <option key={i} value={key}>{key}</option>)}
        </Select>
      </div>
      <div className="editor-popper-menu__column">
        <Button
          className='editor-popper-menu__control'
          type='button'
          variant='outline'
          onClick={onClearBlock}
        >
          Clear
        </Button>
      </div>
    </div>
  );
};
const PopperMenu: React.FC<any> = ({ vRef, editor }: { vRef: any, editor: Editor }) => {
  return (
    <Popper
      referenceElement={vRef}
      placement='right-start'
      positionFixed={true}
    >
      {({ ref, style, placement, arrowProps }) => (
        <React.Fragment>
          <div className='editor-popper-menu' ref={ref} style={{ ...style, margin: 10 }} data-placement={placement}>
            <PopperMenuContent editor={editor} />
            <div ref={arrowProps.ref} style={arrowProps.style} />
          </div>
          <div className='editor-popper-menu editor-popper-menu__mobile-fixed'>
            <PopperMenuContent editor={editor} />
          </div>
        </React.Fragment>
      )}
    </Popper>
  );
};

class LyricSectionEditor extends Component<{ sectionData, onChange }> {
  handleChange = ({ value }) => {
    this.props.onChange({ ...this.props.sectionData, slateValue: value });
  };

  handleTitleChange = evt => {
    this.props.onChange({ ...this.props.sectionData, title: evt.target.value });
  };

  handlePaste = evt => {
    evt.preventDefault();
    const text = evt.clipboardData.getData("text/plain");
    const content = text.split('\n').map(str => `<div>${str}</div>`).join('');
    document.execCommand("insertHTML", false, content);
  };

  renderInline = (props: RenderInlineProps, editor, next) => {
    const { children, attributes, node } = props;

    switch (node.type) {
      case LYRIC_BLOCK_INLINE_TYPE:
        return (
          <span
            className='editor-block'
            {...attributes}
            data-lyric-key={node.data.get('key')}
            data-lyric-chord={node.data.get('chord')}
            data-lyric-display-name={getChordName(node.data.toObject() as any)}
          >
            {children}
          </span>
        );
      default:
        return next();
    }
  };

  renderMenu = (editor: Editor) => {
    const { sectionData: { slateValue } } = this.props
    const { fragment, selection } = slateValue;

    if (selection.isCollapsed || fragment.text === '') {
      return null;
    }

    const native = window.getSelection();
    const range = native.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    const vRef = {
      getBoundingClientRect: () => rect,
      clientWidth: rect.width,
      clientHeight: rect.height,
    };

    return (
      <PopperMenu vRef={vRef} editor={editor} />
    );
  }

  renderEditor: any = (props: EditorProps, editor: Editor, next) => {
    const children = next();

    return (
      <React.Fragment>
        {children}
        {this.renderMenu(editor)}
      </React.Fragment>
    );
  };

  render() {

    const { title, slateValue } = this.props.sectionData;
    return (
      <div className='editor-section-wrapper'>
        <div className='editor-title-wrapper'>
          <Input
            onChange={this.handleTitleChange}
            placeholder='Section title e.g. Chorus, Tag, Bridge, Verse 1, etc'
            value={title}
          />
        </div>
        <Editor
          className='editor-viewer'
          value={slateValue}
          onChange={this.handleChange}
          renderEditor={this.renderEditor}
          renderInline={this.renderInline}
        />
      </div>
    );
  }
}

export default LyricSectionEditor;
