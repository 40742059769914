import { createAction } from '.';


export const CHECK_AUTH = Symbol('CHECK_AUTH');
export const CHECK_AUTH_SUCCESS = Symbol('CHECK_AUTH_SUCCESS');
export const CHECK_AUTH_FAILURE = Symbol('CHECK_AUTH_FAILURE');

export const SIGNIN = Symbol('SIGNIN');
export const SIGNUP = Symbol('SIGNUP');
export const LOGOUT = Symbol('LOGOUT');

export const TOGGLE_LOGIN_MODAL = Symbol('TOGGLE_LOGIN_MODAL');

export const checkAuth = createAction(CHECK_AUTH);
export const checkAuthSuccess = createAction(CHECK_AUTH_SUCCESS);
export const checkAuthFailure = createAction(CHECK_AUTH_FAILURE);

export const toggleLoginModal = createAction(TOGGLE_LOGIN_MODAL);
export const signin = createAction(SIGNIN);
export const signup = createAction(SIGNUP);
export const logout = createAction(LOGOUT);
