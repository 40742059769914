import { combineReducers } from 'redux';
import { authReducer } from './auth';
import { lyricsReducer } from './lyrics';
import { lyricReducer } from './lyric';

export default combineReducers({
  auth: authReducer,
  lyrics: lyricsReducer,
  lyric: lyricReducer
});
