import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { firebaseApp } from '../config/firebase';

class PrivateRoute extends Component<any> {
  state = {
    user: null,
    authReady: false
  };
  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged(user => {
      this.setState({ user, authReady: true });
    });
  }
  render() {
    const { user, authReady } = this.state;

    if (authReady) {
      if (user) {
        return this.props.children;
      } else if (this.props.location.pathname !== '/login') {
        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { redirectUrl: this.props.location }
            }}
          />
        )
      }
    }

    return null;
  }
}

export default withRouter(PrivateRoute);
