import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Flex } from 'rebass';
import logo from '../images/note.svg';
import { toggleLoginModal, logout } from '../actions/auth';
import { Toolbar } from './Toolbar';
import { NavLink } from './NavLink';

class NavHeader extends Component<any> {
  render() {
    return (
      <Toolbar>
        <NavLink to='/' className='app-heading'>
          <img src={logo} className="app-logo" alt="logo" />
          Lyrical
        </NavLink>
        <Flex alignItems='center' ml='auto'>
          {this.props.auth.user ?
            <React.Fragment>
              <span className='app-nav-text'>Hi {this.props.auth.user.displayName}</span>
              &middot;
              <NavLink onClick={this.props.logout}>Logout</NavLink>
            </React.Fragment>
            :
            <NavLink to='/login'>Login</NavLink>
          }
          <NavLink to='/new-lyric' className='app-nav-button'>Create Lyric</NavLink>
        </Flex>
      </Toolbar>
    );
  }
}
export default connect(state => state, { toggleLoginModal, logout })(NavHeader);
